import GATSBY_COMPILED_MDX from "/opt/build/repo/blog/writing-article/index.mdx";
import React from "react";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
function BlogPost({data, children}) {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, null, React.createElement("h2", {
    className: "text-4xl py-4 font-semibold leading-snug"
  }, data.mdx.frontmatter.title), React.createElement("p", {
    className: "py-2"
  }, data.mdx.frontmatter.date), React.createElement("div", {
    className: "text-center"
  }, React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt,
    className: "w-4/5 m-4"
  })), children);
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      date(formatString: "yyyy.MM.DD")
      hero_image_alt
      hero_image_credit_link
      hero_image_credit_text
      hero_image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`;
