/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "これを読んだ。"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://zenn.dev/nameless_sn/articles/tech_article_mind"
  }, "技術記事を書かないプログラマへ向けた記事")), "\n", React.createElement(_components.p, null, "技術記事を書こうと思った。\n技術記事を書くことで隅々まで調べるから知識が深まるし、定着するというのはもっともだと思った。\n説明力上げたい。"), "\n", React.createElement(_components.p, null, "一方でこれを読んで慄いてもいる。"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.digital.go.jp/assets/contents/node/basic_page/field_ref_resources/b1ae707f-8dfa-4b40-8003-e8eec74f6a02/32230edd/20221003_meeting_technology_based_regulatory_reform_outline_05.pdf"
  }, "テクノロジーマップ、技術カタログの在り方について"), "　　"), "\n", React.createElement(_components.p, null, "スキルもない私がいきなり「正統的技術系記事」を書くことなどできるはずもないので、そこは積み重ねか。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
